import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap'
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { Container } from '@mui/system';
import axios from 'axios';

function Register(props) {

    const [inputField, setInputField] = useState({
        user_name: "",
        user_phone_num: "",
        user_email: "",
        user_password: "",
        user_role: []
    });

    const [isDone, setIsDone] = useState(false);

    //taking inputs from user and assigning to respective variable
    const onChangeTxt = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value,
        });
    };

    const addRole = (e) => {
        const dt = inputField.user_role
        let newDt = [...inputField.user_role, e.target.value];
        if (dt.includes(e.target.value)) {
            newDt = newDt.filter(val => val !== e.target.value);
        }
        console.log(newDt);
        setInputField({
            ...inputField,
            user_role: newDt
        });
    }

    const onSubmit = (e) => {
        axios.post("/users/add", inputField)
            .then((res) => {
                console.log("User Added");
                setIsDone(true)
            }).catch((err) => {
                console.log("Error", err);
            })
    }

    return <Container maxWidth={"xs"} >
        {
            isDone ? <FormLabel style={{ textAlign: "center", color: "#28a745", fontWeight: "bold", marginTop:"50px" }} component="legend">
                <h4>
                    You have successfully registered.<br />
                    Admin will verify your account soon!
                </h4>
            </FormLabel>
                :
                <Grid container direction="column" rowSpacing={3} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <label style={{ fontWeight: "600", fontSize: "1.5rem", marginTop: "40px" }}>Register with Learning Matters</label>
                    </Grid>
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        <TextField size="medium" name="user_name" id="outlined-basic" label="FULL NAME" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                    </Grid>
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        <TextField size="medium" name="user_phone_num" id="outlined-basic" label="PHONE NO." variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                    </Grid>
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        <TextField size="medium" name="user_email" id="outlined-basic" label="EMAIL" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                    </Grid>
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        <FormGroup row onChange={addRole}>
                            <FormLabel component="legend">Select your role</FormLabel>
                            <FormControlLabel control={<Checkbox />} value={"author"} label="Author" />
                            <FormControlLabel control={<Checkbox />} value={"tester"} label="Tester" />
                            <FormControlLabel control={<Checkbox />} value={"reviewer"} label="Reviewer" />
                            {/* <FormControlLabel control={<Checkbox />} value={"approver"} label="Approver" /> */}
                            {/* <FormControlLabel control={<Checkbox />} value={"supervisor"} label="Supervisor" /> */}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        <TextField size="medium" name="user_password" id="outlined-basic" type="password" label="PASSWORD" variant="outlined" style={{ width: "100%" }} onChange={onChangeTxt} />
                    </Grid>
                    <Grid item xs={12} container alignItems="center" justifyContent="center">
                        <Button variant={"contained"} onClick={onSubmit} style={{ width: "200px", height: "50px", fontWeight: "bold", fontSize: "1rem" }}> REGISTER </Button>
                    </Grid>
                </Grid>
        }

    </Container>
}

export default Register;