import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';


export default function PendingUserTable(props) {

    const [userData, SetUserData] = React.useState([])

    React.useEffect(() => {
        SetUserData(props.userData);
    }, [props.userData])


    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'fullName',
            headerName: 'Full name',
            sortable: false,
            width: 160,
        },
        { field: 'email', headerName: 'Email', width: 300 },
        { field: 'phoneNo', headerName: 'Phone No.', width: 150 },
        {
            field: 'Action',
            headerName: 'Actions',
            sortable: false,
            width: 160,
            renderCell: (cellValues) => {
                return (
                    <Button
                        style={{ color: "rgb(28, 67, 77)" }}
                        onClick={(e) => {
                            e.preventDefault()
                            console.log(cellValues.row);
                            props.setModalShow(true)
                            props.setDtForModal(userData[cellValues.row.id])
                        }}
                    >
                        View
                    </Button>
                );
            }
        }
    ];

    return (
        <div style={{ height: 350, width: '100%' }}>

            <DataGrid
                rows={
                    userData.map((dt, keys) => {
                        let temp = { id: keys, fullName: dt.user_name, email: dt.user_email, phoneNo: dt.user_phone_num }
                        return temp
                    })
                }
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                sx={{
                    '.MuiDataGrid-footerContainer': {
                        border: 'none',
                    },
                    '.MuiDataGrid-columnHeaders' : {
                        backgroundColor: '#8dba23',
                        color: 'whitesmoke'
                    }
                }}
            // checkboxSelection
            />
        </div>
    );
}