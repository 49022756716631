import { Button, Grid } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function SideBar(props) {
    return <Row>
        <Col xs={12} style={{marginTop:"15px"}}>
            <Button style={{width:"40px", height:"50px", backgroundColor:"#ecf5ce", width:"100%", color:"#2d2d2d", fontWeight:"bold"}}>Dashboard</Button>
        </Col>
        <Col xs={12} style={{marginTop:"15px"}}>
            <Button style={{width:"40px", height:"50px", backgroundColor:"white", width:"100%", color:"black"}}>Authors</Button>
        </Col>
        <Col xs={12} style={{marginTop:"15px"}}>
            <Button style={{width:"40px", height:"50px", backgroundColor:"white", width:"100%", color:"black"}}>Testers</Button>
        </Col>
        <Col xs={12} style={{marginTop:"15px"}}>
            <Button style={{width:"40px", height:"50px", backgroundColor:"white", width:"100%", color:"black"}}>Reviewers</Button>
        </Col>
    </Row>
}

export default SideBar;