import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";

function ShowUserModel(props) {

    const [userRoles, setUserRole] = useState(props.data.user_role);

    useEffect(() => {
        console.log("--");
        setUserRole(props.data.user_role)
    }, [props.data])

    const onApprove = () => {
        const body = {
            user_email: props.data.user_email,
            user_role: userRoles
        }
        axios.post("/users/approve_user", body)
            .then((res) => {
                console.log("User approved", res);
            })
            .catch((err) => {
                console.log("Approve error", err);
            })
        props.onHide()
    }

    const onDelete = () => {
        props.onHide()
    }

    const onChangeRole = (e) => {
        const dt = userRoles
        let newDt = [...userRoles, e.target.value];
        if (dt.includes(e.target.value)) {
            newDt = newDt.filter(dt => dt !== e.target.value);
        }
        console.log(newDt);
        setUserRole(newDt);
    }

    return <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Verification
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>{props.data.user_name}</h4>

            <Grid container>
                <Grid item xs={12}>
                    <FormLabel component="legend">Permissions</FormLabel>
                </Grid>
                <Grid item xs={12} alignItems="center" justifyContent="center">
                    <FormGroup row onChange={onChangeRole} >
                        <FormControlLabel control={<Checkbox defaultChecked={props.data.user_role.includes("author") ? true : false} />} value={"author"} label="Author" />
                        <FormControlLabel control={<Checkbox defaultChecked={props.data.user_role.includes("tester") ? true : false} />} value={"tester"} label="Tester" />
                        <FormControlLabel control={<Checkbox defaultChecked={props.data.user_role.includes("reviewer") ? true : false} />} value={"reviewer"} label="Reviewer" />
                        <FormControlLabel control={<Checkbox defaultChecked={props.data.user_role.includes("approver") ? true : false} />} value={"approver"} label="Approver" />
                        <FormControlLabel control={<Checkbox defaultChecked={props.data.user_role.includes("supervisor") ? true : false} />} value={"supervisor"} label="Supervisor" />
                    </FormGroup>
                </Grid>
            </Grid>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={onDelete}>DELETE</Button>
            <Button onClick={onApprove} style={{backgroundColor:"#8dba23"}}>APPROVE</Button>
        </Modal.Footer>
    </Modal>
}

export default ShowUserModel;