import { Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ApprovedUser from "./ApprovedUsers";
import PendingUsers from "./PendingUsers";
import SideBar from "./SideBar";

function MainDashboard(props) {
    const [inputField, setInputField] = useState({
        user_name: "",
        user_phone_num: "",
        user_email: "",
        user_password: "",
    });

    //taking inputs from user and assigning to respective variable
    const onChangeTxt = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value,
        });
    };

    return <Container fluid={true}>
        <Row>
            <Col md={"2"} style={{ borderRight:"3px solid rgb(206, 206, 206)", height: "93vh" }}>
                <SideBar />
            </Col>
            <Col md={"10"}>
                <Row>
                    <Col xs={"12"}>
                        <PendingUsers />
                    </Col>
                    <Col xs={"12"}>
                        <ApprovedUser />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
}

export default MainDashboard;