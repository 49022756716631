import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Register from './Components/RegisterPages/Register';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainDashboard from './Components/DashboardComponents/MainDashboard';
import Navbar from './Components/DashboardComponents/Navbar';
import React, { useEffect } from 'react';


function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Register />} />
        <Route path="/login" element={<MainDashboard />} />
        <Route path="/dashboard" element={<MainDashboard />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App; 