import { Button, Grid } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ShowUserModel from "./ShowUserModal";
import ApprovedUserTable from "./Tables/ApprovedUserTable";

function ApprovedUser(props) {

    const [userData, SetUserData] = useState([])
    const [modalShow, setModalShow] = React.useState(false);
    const [dtForModal, setDtForModal] = React.useState([]);

    useEffect(() => {
        axios.get("/users/get_users")
            .then((res) => {
                console.log("Getting Users", res.data);
                SetUserData(res.data)
            })
            .catch((Err) => {
                console.log("Errorr", Err);
            })
    }, [])

    const UserCard = (props) => {
        return <Grid container item xs={3} rowGap={1} style={{ backgroundColor: "whitesmoke", padding: "15px", borderRadius: "5px" }}>
            <Grid item xs={12}>
                Name: {props.name}
            </Grid>
            <Grid item xs={12}>
                Email: {props.email}
            </Grid>
            <Grid item xs={12}>
                Phone No.:  {props.phoneNum}
            </Grid>
            <Grid item>
                <Button onClick={(e) => {
                    e.preventDefault()
                    setModalShow(true)
                    setDtForModal(userData[props.dbID])
                }}>View</Button>
            </Grid>
        </Grid>
    }

    return <Container maxWidth={false} style={{ marginTop: "20px" }}>
        <h4>Approved Users</h4>
        <ApprovedUserTable userData={userData.filter((e) => { return e.approved === "true" })} setModalShow={setModalShow} setDtForModal={setDtForModal} dbID={props.dbID} />
        {/* <Grid container columnGap={3} style={{ marginTop: "20px", boxShadow: "0px 0px 5px #bfbfbf", borderRadius: "10px", padding: "5px", overflow: "hidden" }} >
            {
                userData.map((dt, id) => {
                    if (dt.approved == "true") {
                        return <UserCard key={id} dbID={id} name={dt.user_name} email={dt.user_email} phoneNum={dt.user_phone_num} />
                    }
                })
            }
        </Grid> */}
        {
            dtForModal.user_name === undefined ? "" : <ShowUserModel
                data={dtForModal}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        }
    </Container>
}

export default ApprovedUser;